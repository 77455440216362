<template>
<transition name="modal">
    <div class="modal__wrapper" @click="$emit('close'); policy = false">
      <div>
        <div v-show="!policy" class="modal-content" @click.stop="">
          <!-- header -->
          <div class="modal-header" v-if="title">
            <span class="modal-title"> {{ title }} </span>
          </div>
          <span class="button-close" title="close" v-if="isClose" @click="$emit('close'); policy = false"></span>
          <!-- body -->
          <div class="modal-body">
            <slot name="body">Default body</slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      policy: false
    }
  },
  created () {
    this.$root.$refs.modal = this
  },
  props: {
    title: {
      type: String,
      required: false
    },
    isClose: {
      type: Boolean,
      required: true
    },
    isPolicy: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') this.$emit('close')
    })
  },
  computed: {
  },
  methods: {
    showPolicy () {
      this.policy = true
    },
    hidePolicy () {
      this.policy = false
    }
  }
}
</script>

<style lang="css">
  /*animations*/
  .modal-enter, .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-content,
  .modal-leave-active .modal-content {
    transform: scale(1.2);
  }
  .modal__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s ease;
    right: 0;
    z-index: 998;
    background-color: rgba(00,00,00,.5);
    backdrop-filter: blur(6px);
  }
  .modal-content {
    position: relative;
    max-width: 320px;
    padding: 20px 18px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    transition: all .2s ease;
    border-radius: 8px;
    z-index: 999;
    overflow: hidden;
    max-height: 600px;
    overflow-y: auto;
  }
  @media screen and (min-width: 900px) {
    .modal-content {
      min-width: 500px;
    }
  }
  .modal-header {
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .modal-header span {
    font-size: 24px;
  }
  .button-close {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../../assets/icons/close.svg");
    transition: all .3s;
  }
  .button-close:hover {
     transform: scale(1.1);
   }
  .modal-body {
    text-align: center;
    padding: 10px;
  }
  .modal-body img {
    width: 100% !important;
    max-width: initial!important;
    border-radius: 5px;
  }
  .modal-bottom {
    padding-top: 40px;
  }
  a.another-modal {
    color: #000;
    border-bottom: 1px solid #000;
  }
</style>
