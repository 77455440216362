import Vue from 'vue'
import VueRouter from 'vue-router'
import pageNotFound from '@/components/404'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Home')
  },
  {
    path: '/1',
    name: 'Home-1',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Home')
  },
  {
    path: '/2',
    name: 'Home-2',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Home')
  },
  {
    path: '/3',
    name: 'Home-3',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Home')
  },
  {
    path: '/franchise',
    name: 'Franchise',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Franchise')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Login')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/components/About')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Articles')
  },
  {
    path: '/month',
    name: 'Month',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Month')
  },
  {
    path: '/month/1',
    name: 'Month-1',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Month')
  },
  {
    path: '/month/2',
    name: 'Month-2',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Month')
  },
  {
    path: '/month/3',
    name: 'Month-3',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Month')
  },
  {
    path: '/referrals',
    name: 'Referrals',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Referrals')
  },
  {
    path: '/cart/:id',
    name: 'Cart',
    props: true,
    component: async () => await import(/* webpackChunkName: "about" */ '@/components/Cart')
  },
  {
    path: '/panel-admin',
    name: 'AdminPanel',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '@/components/AdminPanel')
  },
  {
    path: '/404',
    name: '404',
    component: pageNotFound
  }, {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
