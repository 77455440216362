import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/compat";
import VueScrollTo from 'vue-scrollto'

// eslint-disable-next-line
import {getDatabase, onValue, ref, set} from "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyDQzvEeWHMV79LxGPpfoMPIFVLjVuwQMXI",
  authDomain: "dispozicia-e4397.firebaseapp.com",
  databaseURL: "https://dispozicia-e4397-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dispozicia-e4397",
  storageBucket: "dispozicia-e4397.appspot.com",
  messagingSenderId: "314293012926",
  appId: "1:314293012926:web:08e67538f629a2064191ef",
  measurementId: "G-CB0DCF9QCP"
})

Vue.config.productionTip = false
Vue.use(VueScrollTo)

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /*
    этот путь требует авторизации, проверяем залогинен ли
    пользователь, и если нет, запрещаем доступ к странице
     */
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        next()
      } else {
        console.log('No user is signed in.')
        next({
          path: '/404'
        })
      }
    })
    // if (checkToToken.token) {
    //       // .then((res) => {
    //       //   if (res.data.admin) {
    //       //     next()
    //       //   } else {
    //       //     next({
    //       //       path: '/404'
    //       //     })
    //       //   }
    //       // })
    //       // .catch((error) => {
    //       //   console.log(error)
    //       //   return false
    //       // })
    // } else {
    //   next({
    //     path: '/404'
    //   })
    // }
  } else {
    next() // всегда так или иначе нужно вызвать next()!
  }
})

new Vue({
  router,
  store,
  data () {
    return {
      database: {},
      saveButton: false
    }
  },
  watch: {
    database: {
      // eslint-disable-next-line
      async handler (newVal, oldVal) {
        this.saveButton = true
      },
      deep: false
    }
  },
  methods: {
    async connectDB () {
      const db = await getDatabase();
      const starCountRef = await ref(db, '/');
      await onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        this.database = data.data
        // eslint-disable-next-line
        // updateStarCount(postElement, data);
      });
    },
    async writeUserData(data) {
      await firebase.auth().onAuthStateChanged(function(user) {
        console.log(user)
        if (user) {
          console.log(user)

          console.log('сохранение...')
          const db = getDatabase();
          set(ref(db, '/'), {
            data
          });
        } else {
          console.log('необходимо войти')
        }
        // ...
      })
    },
  },
  async mounted() {
    await firebase.auth().onAuthStateChanged
    await this.connectDB()
  },
  render: h => h(App)
}).$mount('#app')
