<template>
  <footer class="footer">
    <div class="container">
      <div class="foot">
        <div v-if="isMobile" class="foot_one">
          <h1 class="foot_one_title" style="display: flex; flex-direction: column">
            <span style="font-size: 21.5px;">Диспозиция</span>
            <span style="margin: 7px 0">лидер на проекте</span>
            <a target="_blank" href="https://reiting-uristov.ru/" class="foot_one_title_link">РЕЙТИНГ ЮРИСТОВ</a>
          </h1>
          <p class="foot_one_title-copy">Все права защищены © 2022</p>
          <h1 class="foot_one_info">Работаем под контролем Министерства Юстиции РФ</h1>
          <div class="foot_one_df">
            <img src="../assets/icons/pdf.svg" class="foot_one_df_img" alt="404">
            <a target="_blank" href="https://bez-urista.ru/docs/anp_pay.pdf" class="foot_one_df_title">Скачать наши документы</a>
          </div>
          <div class="foot_one_dfTw">
            <img src="../assets/icons/pdf.svg" class="foot_one_dfTw_img" alt="404">
            <a target="_blank" href="https://bez-urista.ru/docs/dogovor_abon_obsluj.doc" class="foot_one_dfTw_title">Скачать шаблон договора</a>
          </div>
        </div>
        <div v-if="isMobile" class="linear_foot"></div>
        <div class="foot_two">
          <div class="foot_two_box">
            <router-link to="/" v-scroll-to="'#scroll-package'" class="foot_two_title">Что входит в пакет услуг</router-link>
            <img style="margin-left: 10px" src="../assets/icons/footnext.svg" class="foot_two_box_img" alt="404">
          </div>
          <div class="foot_two_box">
            <router-link to="/cart/1" class="foot_two_title">Как это работает</router-link>
            <img style="margin-left: 10px" src="../assets/icons/footnext.svg" class="foot_two_box_img" alt="404">
          </div>
          <div class="foot_two_box">
            <router-link to="/referrals" class="foot_two_title">Бесплатный юрист навсегда</router-link>
            <img style="margin-left: 10px" src="../assets/icons/footnext.svg" class="foot_two_box_img" alt="404">
          </div>
          <div class="foot_two_box">
            <router-link to="/cart/4" class="foot_two_title">Судимся быстро в Третейском суде</router-link>
            <img style="margin-left: 10px" src="../assets/icons/footnext.svg" class="foot_two_box_img" alt="404">
          </div>
          <div class="foot_two_box">
            <router-link to="/cart/5" class="foot_two_title">Юридическое обслуживание малого бизнеса</router-link>
            <img style="margin-left: 10px" src="../assets/icons/footnext.svg" class="foot_two_box_img" alt="404">
          </div>
          <br>
          <div class="foot_two_network" v-if="getDatabase && isMobile">
            <a target="_blank" :href="getDatabase.link.vk">
              <img src="../assets/icons/vk.svg" class="foot_two_network_vk" alt="404">
              <span style="color: #FFFFFF;">Мы во ВКонтакте</span>
            </a>
<!--            <a target="_blank" :href="getDatabase.link.fb"><img src="../assets/icons/facebok.svg" class="foot_two_network_fc" alt="404"></a>-->
<!--            <a target="_blank" :href="getDatabase.link.inst"><img src="../assets/icons/instagram.svg" class="foot_two_network_in" alt="404"></a>-->
          </div>
        </div>
        <div class="linear_footTwo"></div>
        <div class="foot_three" v-if="getDatabase">
          <h1 class="foot_three_title">Онлайн-сервисы</h1>
          <a class="foot_three_info">Онлайн программы и интенсивы для решения юридических вопросов</a>
          <div class="foot_three_box">
            <a target="_blank" :href="getDatabase.services.programs" class="foot_three_box_title">Запишитесь прямо сейчас <img src="../assets/icons/footnextlanger.svg" class="foot_three_box_img" alt="404"></a>
          </div>
          <h1 class="foot_three_titleTwo">Шаблоны документов по всем юридическим вопросам</h1>
          <div class="foot_three_boxTwo">
            <a target="_blank" :href="getDatabase.services.templates" class="foot_three_boXTwo_title">Получите за 299 руб  <img src="../assets/icons/footnextlanger.svg" class="foot_three_boxTwo_img" alt="404"></a>
          </div>
        </div>

        <div v-if="!isMobile" class="linear_foot"></div>
        <div v-if="!isMobile" class="foot_one">
          <h1 class="foot_one_title"><span style="font-size: 21.5px">Диспозиция</span><br> лидер на проекте <br>
            <a target="_blank" href="https://reiting-uristov.ru/" class="foot_one_title_link">РЕЙТИНГ ЮРИСТОВ</a>
          </h1>
          <p class="foot_one_title-copy">Все права защищены © 2022</p>
          <h1 class="foot_one_info">Работаем под контролем Министерства Юстиции РФ</h1>
          <div class="foot_one_df">
            <img src="../assets/icons/pdf.svg" class="foot_one_df_img" alt="404">
            <a target="_blank" href="https://bez-urista.ru/docs/anp_pay.pdf" class="foot_one_df_title">Скачать наши документы</a>
          </div>
          <div class="foot_one_dfTw">
            <img src="../assets/icons/pdf.svg" class="foot_one_dfTw_img" alt="404">
            <a target="_blank" href="https://bez-urista.ru/docs/dogovor_abon_obsluj.doc" class="foot_one_dfTw_title">Скачать шаблон договора</a>
          </div>
        </div>
        <div class="foot_two_network" v-if="getDatabase && !isMobile">
          <a target="_blank" :href="getDatabase.link.vk">
            <img src="../assets/icons/vk.svg" class="foot_two_network_vk" alt="404">
            <span style="color: #FFFFFF;">Мы во ВКонтакте</span>
          </a>
          <!--            <a target="_blank" :href="getDatabase.link.fb"><img src="../assets/icons/facebok.svg" class="foot_two_network_fc" alt="404"></a>-->
          <!--            <a target="_blank" :href="getDatabase.link.inst"><img src="../assets/icons/instagram.svg" class="foot_two_network_in" alt="404"></a>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters([
      'isAUTH'
    ]),
    getDatabase () {
      return this.$root.database.footer
    },
    isMobile () {
      return window.innerWidth > 480
    }
  },
  methods: {
    edit (val) {
      if (this.isAUTH) {
        val.target.contentEditable = 'true'
        val.target.focus()
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.foot_one_title {
  margin-bottom: 0;
}
.foot_one_title-copy {
  color: white;
  margin-bottom: 10px;
}
</style>
