<template>
  <header
      class="header"
  >
    <div class="container">
      <nav class="navbar">
        <router-link to="/" class="navbar-brand"><img class="navbar_img" src="../assets/icons/logo.png" width="200px" alt="404"></router-link>
        <ul class="nav">
          <li class="nav-item">
            <router-link to="/about" class="nav-link">О нас</router-link>
          </li>
          <li v-if="1===2" class="nav-item">
            <router-link to="/about" v-scroll-to="'#scroll-lawyer'" class="nav-link">Ваши юристы</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/month" class="nav-link">Первый месяц бесплатно</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/cart/1" class="nav-link">Как это работает</router-link>
          </li>
          <li class="nav-item">
            <a @click="modal = true" class="nav-link">Оставить заявку</a>
          </li>
        </ul>
      </nav>
      <!-- navMedia -->
      <div class="navbar navbar_media">
        <div class="navbar-wrap">
          <img src="../assets/icons/burger.svg" @click="isMobileMenu" style="cursor: pointer" class="navbar_media_burger" alt="404">
          <router-link to="/" class="navbar-brand"><img class="navbar_img" src="../assets/icons/logo.png" width="150px" alt="404"></router-link>
          <div id="menus" class="menu_text " :class="{'menu_texttwo':mobileClick}">
            <ul class="navmedia menu_texttwo">
              <li class="nav-item">
                <router-link to="/about" href="#" class="nav-link">О нас</router-link>
              </li>
              <li v-if="1===2" class="nav-item">
                <router-link to="/lawyer" class="nav-link">Ваши юристы</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/month" href="#" class="nav-link">Первый месяц бесплатно</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/how-works" href="#" class="nav-link">Как это работает</router-link>
              </li>
              <li class="nav-item">
                <a @click="modal = true" class="nav-link">Оставить заявку</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="mobileClick" class="back-blur" @click="isMobileMenu"></div>
      </div>

      <!-- navMedia -->
    </div>
    <Modal v-if="modal" @close="modal = false" :is-close="false" :is-policy="false">
      <template v-slot:body>
        <Form :type="3"/>
      </template>
    </Modal>
  </header>
</template>

<script>
import Modal from './notifications/Modal'
import Form from "@/components/Form";
export default {
  name: "Header",
  components: {
    Form,
    Modal
  },
  data () {
    return {
      mobileClick: false,
      notification: [],
      modal: false,
      phone: null,
      email: null
    }
  },
  watch: {
    '$route.path': function () {
      this.mobileClick = false
    }
  },
  computed: {
    isSaveBnt () {
      return this.$root.saveButton && localStorage.user
    },
    mobileToDesktop () {
      return window.innerWidth
    },
    fixHeader () {
      return this.$route.path === '/404' || this.$route.path === '/articles' || this.$route.name === 'Cart' || this.$route.path === '/panel-admin'
    }
  },
  methods: {
    addClassHtml () {
      if (!this.activeBurgerMenu) {
        document.getElementsByTagName('html')[0].classList.remove('mob-menu')
      } else {
        document.getElementsByTagName('html')[0].classList.add('mob-menu')
      }
    },
    isMobileMenu () {
      if (!this.mobileClick) document.body.style.overflow = 'hidden'
      if (this.mobileClick) document.body.style.overflow = 'initial'
      this.mobileClick = !this.mobileClick
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  max-width: initial;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
  min-height: initial;
  color: white!important;
  .nav-link {
    color: white!important;
  }
  .navbar {
    margin-bottom: 0 !important;
  }
  .menu_texttwo {
    width: 100%;
    border-radius: 0 0 5px 5px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
  }
}
.back-blur {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  top: 0;
  backdrop-filter: blur(6px);
}
.navmedia {
  top: 50px;
  height: 180px;
  padding: 20px;
}
.navbar-wrap {
  z-index: 300;
}
.nav {
  width: 75%;
  .nav-item {
    cursor: pointer;
  }
}
</style>
