import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import router from "../router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      uid: ''
    }
  },
  mutations: {
    SET_USER_AUTH: (state, data) => {
      state.user.uid = data
    },
    LOGOUT: (state) => {
      state.user.uid = false
      localStorage.removeItem('user')
      router.replace('/')
    },
    CHECK_TO_TOKEN: (state, uid) => {
      if (uid) {
        state.user.uid = uid
      } else state.user.uid = false
    }
  },
  actions: {
    async CHECK_TO_TOKEN ({ commit }) {
      if (localStorage.user) {
        commit('CHECK_TO_TOKEN',localStorage.user)
      } else commit('CHECK_TO_TOKEN',false)
    }
  },
  getters: {
    isAUTH (state) {
      return !!state.user.uid
    }
  },
  modules: {
    auth
  }
})