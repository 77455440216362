<template>
  <div>
    <div v-if="$route.name !== 'Home' && $route.path !== '/1' && $route.path !== '/2' && $route.path !== '/3' && type === 0" class="container">
      <form class="mes">
        <p class="mes_ttitle">Получить месяц бесплатного юридического обслуживания</p>
        <p class="mes_bez">Без обязательств</p>
        <div class="mes_box">
          <input v-model="name" name="name" type="text" class="mes_box_imya" placeholder="Имя">
          <input v-model="phone" name="phone" type="number" class="mes_box_tel" placeholder="Телефон">
          <input v-model="email" name="email" type="email" class="mes_box_email" placeholder="E-mail">
          <button @click.prevent="send" class="send" type="submit"> Месяц бесплатно</button>
        </div>
        <p class="mes_info">
          Отправляя форму вы соглашаетесь с условиями обработки
          <a @click.prevent="showPolicy" href="#" class="mes_link">Персональных данных</a>
        </p>
      </form>
    </div>
    <form v-if="type === 1" class="body_two" action="../../send.php">
      <div class="container">
        <div class="ekonom">
          <p class="ekonom_title">

            {{
              $route.path==='/' ? 'Представьте, теперь у вас есть целый штат специализированных юристов, которые всегда помогут и решат любой вопрос:'
                  :$route.path==='/1' ? 'Представьте, теперь у вас есть целый штат специализированных юристов, которые всегда помогут и решат любой вопрос:'
                      :$route.path === '/2'? 'Представьте, теперь у вас есть целый штат специализированных юристов, которые всегда помогут и решат любой вопрос:'
                          :$route.path === '/3'? 'Представьте, теперь у вас есть целый штат специализированных юристов, которые всегда помогут и решат любой вопрос:'
                              :$route.path === '/franchise'? 'Такого проекта еще не делал никто! Ты же не упустишь возможность быть с нами? :)'
                                  : ''
            }}

          </p>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="im">
                <div class="im_box">
                  <img src="../assets/icons/qulcha.svg" class="im_img" alt="404" v-if="$route.path !== '/franchise'">
                  <p class="im_title" v-if="$route.path !== '/franchise'">
                    Им не нужно платить зарплату и налоги
                  </p>
                </div>
                <div class="za_box">
                  <img src="../assets/icons/qulcha.svg" class="za_img" alt="404" v-if="$route.path !== '/franchise'">
                  <p v-if="$route.path !== '/1' && $route.path !== '/franchise'" class="za_title">За работу есть ответственный</p>
                  <p v-else-if="$route.path !== '/franchise'" class="za_title">Интегрирует Ваш бизнес в правовое поле РФ</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="ne">
                <div class="ne_box">
                  <img src="../assets/icons/qulcha.svg" class="ne_img" alt="404" v-if="$route.path !== '/franchise'">
                  <p class="ne_title" v-if="$route.path !== '/franchise'">
                    Не нужно рабочее место
                  </p>
                </div>
                <div class="resh_box">
                  <img src="../assets/icons/qulcha.svg" class="resh_img" alt="404" v-if="$route.path !== '/franchise'">
                  <p v-if="$route.path !== '/1' && $route.path !== '/franchise'" class="resh_title">Решает вопросы бизнеса и Ваших сотрудников</p>
                  <p v-else-if="$route.path !== '/franchise'" class="resh_title">Решает вопросы бизнеса и отстаивает Ваши интересы</p>
                </div>
              </div>
            </div>
          </div>
          <h5 v-if="$route.path === '/franchise'" style="color: #0b0b0b"><b>Сделай шаг для своего прорыва в этом году — получи возможность открыть этот бизнес пока это не сделал кто-то другой</b></h5>
          <h1 class="ming_title">Экономия в год от 100 000 ₽ и более!</h1>
          <img src="../assets/icons/bayroqcha.svg" class="bayroqcha" alt="404">
          <div class="ekonom_box_input">
            <input v-model="name" name="name" type="text" placeholder="Имя" class="box_input_imya">
            <input v-model="phone" name="phone" type="text" placeholder="Телефон" class="box_input_tel">
            <input v-model="email" name="email" type="text" placeholder="E-mail" class="box_input_email">
            <button v-if="$route.path !== '/franchise'" @click.prevent="sendMail" type="submit" class="send">Оставить заявку</button>
            <button v-if="$route.path === '/franchise'" @click.prevent="sendMail"  type="submit" class="send">Я в деле</button>
          </div>
          <p class="mes_info">
            Отправляя форму вы соглашаетесь с условиями обработки
            <a @click.prevent="showPolicy" href="#" class="mes_link">Персональных данных</a>
          </p>
        </div>
      </div>
    </form>

    <form v-if="type === 2" class="body_six">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="nachala_uslugi">
              <div class="nachala_uslugi_one">
                <div>
                  <h1 class="snachala_uslugi_title">
                    {{
                    $route.path === '/' ? 'Оставь заявку'
                    :$route.path === '/1' ? 'Оставь заявку'
                    :$route.path === '/2' ? 'Оставь заявку'
                    :$route.path === '/3' ? 'Оставь заявку'
                                :$route.path === '/franchise' ? 'Если ты видишь эту рекламу'
                                    : ''
                    }}
                  </h1>
                  <p class="snachala_uslugi_info">
                    {{
                    $route.path === '/' ? 'и получите месяц бесплатного обслуживания в подарок!'
                        :$route.path === '/1' ? 'и получите месяц бесплатного обслуживания в подарок!'
                          :$route.path === '/2' ? 'и получите месяц бесплатного обслуживания в подарок!'
                            :$route.path === '/3' ? 'и получите месяц бесплатного обслуживания в подарок!'
                                :$route.path === '/franchise' ? 'Значит твой город пока свободен. Успеешь его занять?'
                                    : ''
                    }}
                  </p>
                </div>
                <div class="oltmish_box">
                  <div class="oltmish_box_one">
                    <h1 class="oltmish_box_one_title">

                      {{
                        $route.path === '/' ? '62'
                            :$route.path === '/1' ? '62'
                                :$route.path === '/2' ? '62'
                                    :$route.path === '/3' ? '62'
                                        :$route.path === '/franchise' ? '1'
                                            : ''
                      }}

                    </h1>
                    <p class="oltmish_box_one_info" v-html="text"></p>
                  </div>
                  <div class="oltmish_box_two">
                    <h1 class="oltmish_box_two_title">

                      {{
                        $route.path === '/' ? '196'
                            :$route.path === '/1' ? '196'
                                :$route.path === '/2' ? '196'
                                    :$route.path === '/3' ? '196'
                                        :$route.path === '/franchise' ? '1 000 000 +'
                                            : ''
                      }}

                    </h1>
                    <p class="oltmish_box_two_info" v-html="text2"></p>
                  </div>
                </div>
              </div>
              <div class="nachala_inpot">
                <input v-model="name" name="name" type="text" class="nachala_inpot_imya" placeholder="Имя">
                <input v-model="phone" name="phone" type="text" class="nachala_inpot_tel" placeholder="Телефон">
                <input v-model="email" name="email" type="text" class="nachala_inpot_email" placeholder="E-mail">
                <button v-if="$route.path !== '/franchise'" @click.prevent="sendFormData" type="submit" class="btn nachal_inpot">Первый месяц бесплатно</button>
                <button v-else @click.prevent="sendFormData" type="submit" class="btn nachal_inpot">Я успею</button>
              </div>
              <p class="mes_info" style="margin-top: 20px; color: white">
                Отправляя форму вы соглашаетесь с условиями обработки
                <a style="color: white!important;" @click.prevent="showPolicy" href="#" class="mes_link">Персональных данных</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form v-if="type === 3">
      <div class="header-form">
        <p>Юридический аутсорсинг</p>
        <span>Оставьте заявку и мы с вами свяжемся</span>
        <input v-model="phone" name="phone" type="text" class="nachala_inpot_tel" placeholder="Телефон">
        <input v-model="email" name="email" type="text" class="nachala_inpot_email" placeholder="E-mail">
        <button @click.prevent="send" type="submit" class="btn nachal_inpot">Отправить заявку</button>
        <p class="mes_info">
          Отправляя форму вы соглашаетесь с условиями обработки
          <a @click.prevent="showPolicy" href="#" class="mes_link">Персональных данных</a>
        </p>
      </div>
    </form>
    <Modal
        v-show="modal"
        @close="modal = false"
        :isClose="true"
        :is-policy="true"
    >
      <template v-slot:body>
        <div class="gift">
          <img src="../assets/icons/gift.svg" alt="">
          <p>ВЫ ПОЛУЧИЛИ МЕСЯЦ ОБСЛУЖИВАНИЯ В ПОДАРОК</p>
          <div>
            Вам придет письмо где мы расскажем как начать получать качественные юридические услуги уже сегодня!
          </div>
          <button class="sendGift" @click.prevent="modal = false">Отлично</button>
        </div>
      </template>
    </Modal>
    <Modal
        v-show="modalPolicy"
        @close="hidePolicy"
        :isClose="true"
        :is-policy="true"
    >
      <template v-slot:body>
        <div class="" style="color: black">
          <p align="justify">
            <span style="text-align: center; display: block; font-weight: bold">Политика о данных пользователей</span><br><br>
            Эта политика описывает, как АНО «Первое арбитражное учреждение» («компания» или «мы») обрабатывает и защищает персональные данные пользователей сайта АНО «Первое арбитражное учреждение».

            Политика применяется как к зарегистрированным, так и к незарегистрированным пользователям («пользователи» или «вы»). Политика применяется независимо от устройства, с которого вы используете сайт АНО «Первое арбитражное учреждение», и независимо от канала связи, по которому вы обращаетесь.

            Предоставляя нам персональные данные, вы даете согласие на их обработку в соответствии с этой политикой.<br><br>
            1. Определения<br><br>
            Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
            <br><br>
            Обработка персональных данных – осуществление любых действий или совокупности действий в отношении персональных данных, включая сбор, запись, систематизацию, накопление, хранение, обновление и изменение, извлечение, использование, передачу (распространение, предоставление, доступ), блокирование, удаление и уничтожение – как с использованием, так и без использования средств автоматизированной обработки данных.
            <br><br>2. Какие данные мы обрабатываем<br><br>
            Мы обрабатываем персональные и технические данные.<br>
            Вы предоставляете нам персональные данные, когда:<br>
            •	регистрируетесь на сайте АНО «Первое арбитражное учреждение»;<br>
            •	используете сайт АНО «Первое арбитражное учреждение» и услуги компании;<br>
            •	подписываетесь на рассылки;<br>
            •	участвуете в мероприятиях, исследованиях и опросах;<br>
            •	пишете или звоните нам;<br>
            •	общаетесь с другими пользователями;<br>
            •	откликаетесь на вакансии в разделе Карьера;<br>
            •	пользуетесь правами и выполняете обязанности на основании законодательства.<br>
            Ваше устройство автоматически передает технические данные: информацию, сохраненную в файлах куки (cookies), информацию о браузере и его настройках, дате и времени доступа к сайту АНО «Первое арбитражное учреждение», адресах запрашиваемых страниц, действиях на сайте или в приложении, технических характеристиках устройства, IP-адресе и т.п.
            <br><br>3. Для чего мы обрабатываем данные<br><br>
            Мы обрабатываем персональные и технические данные, чтобы:<br>
            •	предоставлять вам возможность пользоваться сайтом АНО «Первое арбитражное учреждение» и услугами компании: задавать вопросы юристу и присылать документы для получения консультации, оплачивать услуги и т.п.;<br>
            •	обеспечивать стабильную работу и безопасность сайта АНО «Первое арбитражное учреждение», улучшать пользовательский опыт, качество услуг и маркетинговых мероприятий компании;<br>
            •	предупреждать и пресекать нарушения законодательства, условий использования сайта АНО «Первое арбитражное учреждение» и договора оказания услуг;<br>
            •	отвечать на ваши обращения;<br>
            •	выполнять обязанности, предусмотренные законодательством (например, для бухгалтерского учёта, налоговой отчётности, ответов на запросы государственных органов);<br>
            •	направлять маркетинговые сообщения об АНО «Первое арбитражное учреждение» и наших партнерах;<br>
            •	организовывать ваше участие в мероприятиях, исследованиях и опросах компании и наших партнеров;<br>
            •	подписывать пользователей на каналы в сети интернет, в том числе и не исключая https://t.me/vernut_prava
            и для других целей с вашего согласия.<br>
            <br><br>4. Правовые основания обработки<br><br>
            Мы обрабатываем ваши персональные данные:<br>
            •	с вашего согласия;<br>
            •	для заключения, исполнения, изменения или прекращения договора – Условий использования сайта АНО «Первое арбитражное учреждение»  и договора оказания услуг;<br>
            •	для осуществления прав и законных интересов компании или третьих лиц, если при этом не нарушаются ваши права и свободы;<br>
            •	для выполнения обязанностей, возложенных на компанию законодательством;<br>
            •	для передачи запрашиваемой информации.<br>
            Мы не обрабатываем специальные категории персональных данных. Мы не обрабатываем биометрические персональные данные для установления личности.<br>
            <br><br>5. Передача третьим лицам. Трансграничная передача<br><br>
            Мы можем передавать персональные данные или поручать их обработку третьим лицам внутри нашей организации и партнерским организациям. Например, если Вы оставили свой номер телефона и информацию о вашей ситуации для получения консультации, мы передаем ваши данные юридической службе.
            Для получения информации по запрашиваемой Вами тематике Пользователю может быть рекомендована бесплатная подписка на канал Автоюрист Priority https://t.me/vernut_prava, отказ от которой осуществляется путем выхода из данного канала нажатием кнопки «покинуть канал».
            Мы передаем персональные данные только в те страны, которые обеспечивают их адекватную защиту.<br>
            Мы никогда не передадим ваши данные спамерам.<br>
            <br><br>6. Сайт АНО «Первое арбитражное учреждение» – открытый источник данных<br><br>
            Относитесь ответственно к размещению информации в интернете. Ваши отзывы и публичную часть профиля сможет увидеть любой пользователь интернета, зайдя на сайт АНО «Первое арбитражное учреждение».<br>
            Цель, с которой пользователи размещают данные на сайтах АНО «Первое арбитражное учреждение»  – получение юридической помощи. Запрещено обрабатывать данные пользователей для любых других целей. Нельзя копировать данные пользователей, чтобы размещать и хранить их на других сервисах. Нельзя использовать открытые данные для скоринга. Все это – незаконно.
            <br><br>7. Безопасность<br><br>
            Ответственное отношение к персональным данным – стандарт работы компании.<br>
            Для защиты персональных данных, мы:<br>
            •	Издали и опубликовали на сайте политику о данных пользователей. Утвердили локальные акты по вопросам обработки и защиты персональных данных. Работники знакомятся с этими документами в свой первый день в компании.<br>
            •	Назначили лицо, ответственное за организацию обработки персональных данных.<br>
            •	Проводим тренинги для работников по теме персональных данных.<br>
            •	Регулярно проверяем процессы и документы компании на их соответствие закону.<br>
            •	Оцениваем риски и вред, который можем причинить, если нарушим закон о персональных данных. С учетом проведенной оценки подбираем подходящие меры для соблюдения закона.<br>
            •	Применяем правовые, организационные и технические меры, чтобы обеспечить безопасность персональных данных.<br>
            Принимая меры по защите персональных данных, мы основываемся на:<br>
            •	требованиях законодательства;<br>
            •	установленном уровне защищенности персональных данных;<br>
            •	актуальных угрозах, определенных моделью угроз;<br>
            •	базовом наборе мер защиты, установленном нормативными правовыми актами для соответствующего уровня защищенности;<br>
            •	риск-ориентированном подходе при выборе оптимальных мер;<br>
            •	приоритете законных интересов пользователей.<br>
            <br><br>8. Сроки обработки<br><br>
            Мы прекращаем обработку ваших персональных данных:<br>
            •	по истечении срока согласия или при отзыве согласия (если нет других оснований для обработки, предусмотренных законодательством);<br>
            Вы даете согласие на 5 лет с даты удаления вашего профиля на сайте АНО «Первое арбитражное учреждение». Другой срок может быть предусмотрен в отдельных случаях;<br>
            •	по достижении целей обработки либо при утрате необходимости в их достижении (если нет других оснований для обработки, предусмотренных законодательством);<br>
            •	при выявлении неправомерной обработки, если обеспечить правомерность невозможно;<br>
            •	при ликвидации компании;<br>
            •	при отзыве согласия от Вас, как от пользователя.<br>
            Закон требует, чтобы мы хранили информацию о пользователе в течение года после удаления профиля.<br>
            <br><br>9. Связаться с нами<br><br>
            Чтобы задать вопрос или отозвать согласие на обработку персональных данных, обратитесь в службу поддержки.<br>
            <br><br>10. Изменение политики<br><br>
            Мы обновляем политику по мере необходимости. Периодически проверяйте эту страницу.<br>
            Продолжая использовать сайт и ресурсы АНО «Первое арбитражное учреждение»  после изменения политики, Вы подтверждаете согласие с внесенными в нее изменениями.<br>
            <br><br>
            АНО «Первое арбитражное учреждение»<br>
            Москва, Автозаводская, 23а к2<br><br>
          </p>
          <button class="sendGift" @click.prevent="modalPolicy = false">Понятно</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import Modal from './notifications/Modal'
import querystring from 'querystring'
export default {
  name: "Form",
  components: {Modal},
  props: {
    type: {
      type: Number,
      default: 0
    }
  },

  computed: {
    text() {
      if (this.$route.path === '/') {
        return 'юриста<br> разных специализаций';
      } else if (this.$route.path === '/1') {
        return 'юриста<br> разных специализаций';
      } else if (this.$route.path === '/2') {
        return 'юриста<br> разных специализаций';
      } else if (this.$route.path === '/3') {
        return 'юриста<br> разных специализаций';
      } else if (this.$route.path === '/franchise') {
        return 'партнер в одном<br> регионе России';
      }
      // Добавь другие варианты для $route.path, если необходимо

      return '';
    },
    text2() {
      if (this.$route.path === '/') {
        return 'компаний на<br> обслуживании';
      } else if (this.$route.path === '/1') {
        return 'компаний на<br> обслуживании';
      } else if (this.$route.path === '/2') {
        return 'компаний на<br> обслуживании';
      } else if (this.$route.path === '/3') {
        return 'компаний на<br> обслуживании';
      } else if (this.$route.path === '/franchise') {
        return 'чистой прибыли<br> в месяц';
      }
      // Добавь другие варианты для $route.path, если необходимо

      return '';
    }
  },

  data () {
    return {
      name: null,
      email: null,
      phone: null,
      modal: false,
      modalPolicy: false
    }
  },
  methods: {

    showToast() {
      const toast = document.createElement('div');
      toast.className = 'toast';
      toast.textContent = 'Привет, это всплывающее оповещение!';
      document.body.appendChild(toast);

      setTimeout(() => {
        toast.style.display = 'none';
      }, 3000); // Скрыть оповещение через 3 секунды (или другой удобный интервал)
    },

    send (event) {

        event.target.innerText = 'Отправлено!'
        let formData = new FormData()
        formData.append('crm', '16')
        formData.append('pipe', '34')
        formData.append('contact[name]', this.name)
        formData.append('contact[466]', this.phone)
        formData.append('contact[467]', this.email)
        formData.append('contact[473]', '')
        formData.append('lead[471]', '')
        formData.append('lead[534]', '')
        if (this.$route.path === '/') {
          formData.append('lead[541]', 'dispozicia.ru')
        } else if (this.$route.path === '/1') {
          formData.append('lead[541]', 'dispozicia.ru/1')
        } else if (this.$route.path === '/2') {
          formData.append('lead[541]', 'dispozicia.ru/2')
        } else if (this.$route.path === '/3') {
          formData.append('lead[541]', 'dispozicia.ru/3')
        } else if (this.$route.path === '/franchise') {
          formData.append('lead[541]', 'dispozicia.ru/franchise')
        }
        formData.append('lead[468]', '')
        formData.append('lead[469]', '')
        const formToMail = {
          name: this.name || 'Не указано',
          email: this.email || '',
          phone: this.phone,
          message: this.$route.path
        }
        axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))
        axios.post('https://bez.v-avtoservice.com/api/import-lead',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(res => {
          if (res.data.link.result.payment_link) window.location.href = res.data.link.result.payment_link
          else this.modal = true
          this.$route.name === 'Home' && (event.target.innerText = 'Хочу таких юристов!')
          this.$route.name === 'About' && (event.target.innerText = 'Месяц бесплатно')
        })

    },


    sendMail(event) {
      this.sendFormData(event)
      this.showToast()
    },

    sendFormData(event) {

      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('phone', this.phone);
      formData.append('email', this.email);

      axios.post('https://dispozicia.ru/send.php', formData)
          .then(response => {
            // Обработка успешного ответа от сервера
            console.log(response.data);
            event.target.innerText = "Отправлено!";
            alert("Ваша заявка успешно отправлена!");



          })
          .catch(error => {
            // Обработка ошибок при отправке запроса
            console.error(error);
            alert("Ошибка при отправке письма");
          });
    },

    showPolicy() {
      this.modalPolicy = true
      document.body.style.overflow = 'hidden'
    },
    hidePolicy() {
      this.modalPolicy = false
      document.body.style.overflow = 'initial'
    }
  }
}
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.mes {
  margin-top: -60px;
  width: 100%;
  min-height: 214px;
  background: linear-gradient(95.48deg, #F4DD61 0%, #51D485 100%);
  padding: 28px 32px;
}
.bayroqcha {
  bottom: 110px;
}
.gift {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2f2f2f;
  img {
    width: 210px!important;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin: 40px 0;
  }
  div {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}
.sendGift {
  width: 126px;
  height: 48px;
  left: 152px;
  top: 515px;
  background: #51D585;
  border-radius: 5px;
  border: none;
  color: white;
}
.send {
  padding: 15px 24px;
  background: #F4DD61;
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
}
.header-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: black;
    font-size: 28px;
    margin-bottom: 10px;
    opacity: .85;
    text-transform: uppercase;
  }
  span {
    color: black;
    margin-bottom: 25px;
    opacity: .70;
  }
  .btn {
    padding: 15px 24px;
    background: #51D585;
    color: white!important;
    border-radius: 5px;
    text-align: center;
    border: none;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    margin: 0;
    margin-bottom: 20px;

    width: 268px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    padding-left: 20px;
    color: black;
    :last-child {
      margin-bottom: 20px;
    }
  }
}
.mes_info {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px!important;
  line-height: 18px;
  color: #000000;
  margin-top: 20px;
  opacity: 0.5 !important;
  &:hover {
    opacity: 1!important;
  }
}

.mes_link {
  font-family: Montserrat;
  font-size: 10px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #40572E!important;
  margin-left: 2px;
  text-decoration: underline;
  opacity: 0.5 !important;
  &:hover {
    opacity: 1!important;
  }
}
</style>
