<template>
  <section>
    <div class="wrapper">
      <div class="body">
        <div class="container">
          <div class="error">
            <p class="error_title">404</p>
            <p class="error_info">Кажется, такой страницы не существует</p>
            <button type="button" class="btn error_knopka">На главную</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "pageNotFound"
}
</script>
<style scoped>
@import "../assets/css/end.css";
@import "../assets/css/endMedia.css";
</style>