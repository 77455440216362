<template>
  <div id="app">
    <div class="wrapper" style="min-height: 100vh">
      <Header v-if="$route.path !== '/login'&&$route.path !== '/panel-admin'"/>
      <router-view class="view"/>
      <Footer v-if="$route.path !== '/login'&&$route.path !== '/panel-admin'"/>
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/css/bootstrap.css";
</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { mapActions } from 'vuex'
export default {
  components: {Footer, Header},
  methods: {
    ...mapActions([
      'CHECK_TO_TOKEN'
    ])
  },
  async mounted() {
    await this.CHECK_TO_TOKEN()
    document.body.classList.add('wrapper')
  }
}
</script>
<style lang="scss">
body {
  background: white!important;
}
</style>
